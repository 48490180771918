import { ThemeConfig } from 'antd';

export const mainTheme: ThemeConfig = {
  token: {
    colorPrimary: '#202124',
    fontFamily: 'Inter, sans-serif',
  },
  components: {
    Menu: {
      radiusItem: 0,
      colorItemBg: '',
      colorItemBgHover: '#413D5080',
      colorItemBgActive: '#413D5080',
      colorItemBgSelected: '#413D5080',
      controlItemBgActiveHover: '#413D5080',
      colorItemText: '#767687',
      colorItemTextHover: '#FBFBFF',
      colorItemTextSelected: '#FBFBFF',
      marginXXS: 0,
      marginXS: 0,
    },
    Title: {
      fontSizeHeading2: 32,
    },
    Text: {
      colorPrimary: '#FBFBFF',
      fontSize: '14px',
      lineHeight: '16.94px',
    },
    Paragraph: {
      fontSize: '14px',
      lineHeight: '16.94px',
      fontSizeHeading2: '32px',
      lineHeightHeading2: '38.4px',
      fontSizeHeading4: '17px',
    },
    Sider: {},
    Button: {
      borderRadius: 8,
      colorPrimary: '#FBFBFF',
      colorText: '#FBFBFF',
      // @ts-ignore
      colorItemTextDisabled: '#9499A3',
      colorBgContainerDisabled: '#E2E7EC',
      colorPrimaryHover: ' #FBFBFF',
      paddingContentHorizontal: 24,
      colorPrimaryActive: ' #FBFBFF',
    },
    Checkbox: {
      colorPrimary: '#202124',
      colorBorderSecondary: '#202124',
      colorPrimaryBg: 'white',
      colorBorder: '#202124',
      colorPrimaryTextActive: 'white',
      borderRadiusSM: 0,
      colorPrimaryHover: 'white',
    },
    Input: {
      borderRadius: 8,
      colorText: '#FBFBFF',
      fontFamily: 'Inter, sans-serif',
      colorBorder: '#474755',
      colorBgContainer: '#ffffff00',
      colorPrimary: '#F3F3F8',
      colorPrimaryHover: '#F3F3F8',
      colorError: '#F16063',
      colorErrorBorder: '#F16063',
      colorErrorBorderHover: '#F16063',
      colorBgContainerDisabled: '#ffffff00',
      colorIcon: '#767687',
      colorIconHover: '#FBFBFF',
      colorTextPlaceholder: '#767687',
    },
    DatePicker: {
      controlHeight: 40,
      colorBgElevated: '#2B2B36',
      colorBorder: '#474755',
      colorText: '#FBFBFF',
      colorTextHeading: '#FBFBFF',
      colorIcon: '#FBFBFF',
      colorTextDisabled: '#767687',
      colorPrimaryBorder: '',
      colorPrimaryHover: '#7c2f94',
      colorPrimary: '#58BF92',
      controlHeightSM: 28,
      colorBgContainer: '#2B2B36',
      colorTextPlaceholder: '#FBFBFF',
      colorIconHover: '#FBFBFF',
    },
    Select: {
      colorText: '#FBFBFF',
      colorBgContainer: '#2B2B36',
      colorBgContainerDisabled: '#2B2B36',
      colorTextLabel: '#FBFBFF',
      colorTextDisabled: '#474755',
      colorErrorHover: '#F16063',
      borderRadius: 4,
      borderRadiusSM: 4,
      borderRadiusLG: 4,
      borderRadiusXS: 4,
      controlHeight: 40,
    },
    Form: {
      colorError: '#F16063',
      colorText: '#FBFBFF',
      colorTextHeading: '#FBFBFF',
    },
    Typography: {
      colorLink: '#404348', //TODO Поменять как будет
      colorLinkHover: '#404348',
      colorLinkActive: '#404348',
      colorPrimary: '#FBFBFF',
      colorText: '#FBFBFF',
      colorTextHeading: '#FBFBFF',
    },
    Avatar: {
      colorPrimaryBg: '#9499A3',
      // @ts-ignore
      colorItemText: '#FFFFFF',
      borderRadius: 4,
      fontWeight: 700,
    },
    Dropdown: {
      borderRadiusLG: 4,
      borderRadiusSM: 4,
      colorBgElevated: 'transparent',
      colorText: '#FBFBFF',
      controlHeight: 40,
      boxShadowSecondary: 'transparent',
      paddingXXS: 0,
      paddingXS: 0,
      controlItemBgHover: '#0E0E13',
      controlItemBgActive: '#0E0E13',
      controlItemBgActiveHover: '#0E0E13',
    },
    Tag: {
      lineHeight: 1.2,
    },
    Modal: {
      borderRadiusLG: 24,
      paddingMD: 40,
      paddingContentHorizontalLG: 40,
      fontSizeHeading5: 24,
      colorTextDescription: '#FFF',
    },
    Breadcrumb: {
      colorTextDescription: '#6B6E75',
      colorText: '#202124',
      marginXS: 8,
      colorBgTextHover: '#00000000',
    },
    Steps: {
      controlHeightLG: 0,
      colorPrimary: '#088473',
      controlItemBgActive: '#088473',
      colorText: '#FAFAFC',
      colorTextDescription: '#767687',
      colorSplit: '#767687',
    },
    Badge: {
      colorBorderBg: 'transparent',
    },
    Popover: {
      colorBgElevated: '#2B2B36',
      boxShadowSecondary: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    },
    Radio: {
      borderRadius: 0,
      colorBorder: '#E2E7EC',
      colorBgContainer: '#F3F5F7',
      colorPrimary: '#404348',
      colorPrimaryActive: '#404348',
      colorPrimaryHover: '#404348',
      colorText: '#202124',
    },
    Skeleton: {
      color: 'rgba(43, 43, 54, 0.35)',
      colorGradientEnd: '#2B2B36',
    },
  },
};
