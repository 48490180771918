import { Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import { CloseIcon } from '../../../../assets/img/icons/icons';
import { t } from 'i18next';
import { axios } from '../../../../shared/exios';
import { EDENEX_ACCOUNT_URL } from "../../../../packages/keycloak-client/constants";
import { useCookiesCustom } from '../../../../hooks/useCookieCustom';
import {
  CancelButtonStyled, ChangeNicknameModalFormInputLabelStyled,
  ChangeNicknameModalFormStyled,
  ChangeNicknameModalStyled,
  CustomSubmitButton,
  IconCloseStyled,
} from './styles';

interface IChangeEmailModal {
  refetch: ()=> void;
  showModalNickname: boolean;
  setShowModalNickname: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChangeNicknameModal = ({
                               showModalNickname,
                               setShowModalNickname,
                               refetch
                          }: IChangeEmailModal): JSX.Element => {
  const {cookie} = useCookiesCustom();
  const [form] = Form.useForm();
  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState(false);

  const handleCancelNicknameModal = () => {
    setShowModalNickname(false);
    setBtnSubmitDisabled(true);
  };

  const changeNicknameHandler = () => {
    validateNickname('', form?.getFieldValue('newNickname')).then(postFormData)
  };

  const handleFieldValueChange = () => {
    form.validateFields().then();
  };

  const postFormData = async () => {
    try {
      await axios.post(
        `${EDENEX_ACCOUNT_URL}/edenex-account/api/update-nickname`,
        {
          nickname: form.getFieldValue('newNickname'),
        },
        {
          headers: {
            Authorization: `Bearer ${cookie.token?.replace('Bearer ', '')}` || axios.defaults.headers['Authorization'],
          },
        },
      ).then((res) => {
        if (res) {
          setTimeout(()=> {
            notification.success({
              message: (
                <span
                  style={{
                    fontSize: '16px',
                    fontWeight: '700',
                    color: '#FBFBFF',
                  }}>
              {t('notifications.nicknameChanged')}
            </span>
              ),
              style: { background: '#2B2B36' },
              placement: 'topRight',
              duration: 2,
            });
          }, 500)
          refetch();
        }
      });
    } catch (e: any) {
      const errors = (str: string) => {
        if (str.includes('A moratorium has been imposed on the user')) {
          return t('На данного пользователя наложен мораторий'); 
        } else {
          return t('Никнейм уже занят');
        }
      };

      if (e.response.status === 422) {
        form.setFields([
          {
            name: 'newNickname',
            errors: [errors(e?.response?.data.message)],
          },
        ]);

      }
    }
  };

  const validateNickname = async (_: any, value: string) => {
    if (value.trim().length <= 0) {
      setBtnSubmitDisabled(true)
      return Promise.reject(
        new Error(t('titles.enterNickname').toString()),
      );
    }
    return Promise.resolve();
  };

  return (
      <ChangeNicknameModalStyled
        title={t('titles.changeNickname')}
        open={showModalNickname}
        closeIcon={
          <IconCloseStyled
            component={CloseIcon}
            onClick={() => setShowModalNickname(false)}
          />
        }
        okText={t('buttons.change').toString()}
        cancelText={t('buttons.cancel').toString()}
        width={560}
        centered
        maskStyle={{
          backdropFilter: 'blur(9px)',
        }}
        closable={true}
        destroyOnClose={true}
        footer={[
          <CustomSubmitButton
            key='submit'
            type='primary'
            disabled={btnSubmitDisabled}
            onClick={changeNicknameHandler}
          >
            {t('buttons.change')}
          </CustomSubmitButton>,
          <CancelButtonStyled
            key='cancel'
            className={'CancelButton'}
            onClick={handleCancelNicknameModal}
          >
            {t('buttons.cancel')}
          </CancelButtonStyled>,
        ]}
      >
        <ChangeNicknameModalFormStyled
          form={form}
          onValuesChange={handleFieldValueChange}
          preserve={false}
          name='basic'
          autoComplete='off'
          layout={'vertical'}
          requiredMark={false}
          onFieldsChange={() => setBtnSubmitDisabled(false)}
        >
          <Form.Item
            label={
              <ChangeNicknameModalFormInputLabelStyled>
                {t('inputs.labels.enterNewNickname')}
              </ChangeNicknameModalFormInputLabelStyled>
            }
            name='newNickname'
            rules={[
              { validator: validateNickname }
            ]}
          >
            <Input maxLength={255} placeholder={t('authorizationForms.nickname').toString()} />
          </Form.Item>
        </ChangeNicknameModalFormStyled>
      </ChangeNicknameModalStyled>
  );
};

export { ChangeNicknameModal };
